import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import About from "../components/About"


const AboutPage = () => {

  const { allMarkdownRemark } = useStaticQuery(graphql`
    query AboutImages {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(imagens)/"  }}) {
        edges {
          node {
            id
            frontmatter {
              img {
                                    childImageSharp {
                                        fluid(quality: 100) {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
            }
          }
        }
      }
    }
  `)

  const data = allMarkdownRemark.edges

  return (
    <Layout title="apresentação">
      <SEO title="About" />
      <About images={data}/>
    </Layout>
  )
}

export default AboutPage