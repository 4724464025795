import styled from 'styled-components'
import media from 'styled-media-query'

export const BackgroundBox = styled.div`
    width: 80%;
    height: 100%;
    display: block;
    background-color: #182234;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    position: absolute;
    z-index: 10;
    top: 350px;
    left: 0;
    
    border-radius: 0 23% 0 0;

    ${media.lessThan("large")`
        height: 140%;
    `}

    ${media.lessThan("medium")`
        display: none;
    `}
`

export const AboutSecContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 100;

    margin-top: 80px;
`

export const Header = styled.div`

    font-family: 'Titillium Web', sans-serif;

    h3 {
        font-weight: 700;
        position: absolute;
        right: 30%;
        top: -80px;
        z-index: 50;
        font-size: 27rem;
        color: #fff;
        opacity: 0.15;
        display: block;
        letter-spacing: -33.8px;
    }

    ${media.lessThan("large")`
        h3 {
            right: 20%;
        }
    `}

    ${media.lessThan("medium")`

        display: none;

    `}
`

export const AboutInnerContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    position: relative;
    z-index: 100; 

    ${media.lessThan("large")`
        padding-top: 80px;
    `}
    
    ${media.lessThan("medium")`
        padding: 0;
        margin-right: 0;
    `}
`

export const AboutImageContainer = styled.div`
    width: 100%;
    max-width: 550px;

    margin-top: 50px;

    

    ${media.lessThan("medium")`
        display: none;
    `}
`

export const TextContainer = styled.div`
    text-align: left;
    width: 510px;
    
    margin-left: 120px;

    font-family: 'Montserrat', sans-serif;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h2 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 2.8rem;
        font-weight: 700;
        color: #0C8D85;
        text-transform: uppercase;
    }
    
    h6 {
        font-family: 'Titillium Web', sans-serif;
        font-size: 1.65rem;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
    }

    hr {
        
        border: none;
        height: 3px;
        background-color: #0C8E86;
        width: 40px;
        margin-left: 0;
    }

    h4 {
        font-size: 0.8rem;
        font-weight: 700;
        color: #fff;
        letter-spacing: 6.4px;
        line-height: 1.1rem;
        
        margin: 8px 0 30px 0;
    }

    ul {
        line-height: 33px;

        margin-bottom: 50px;

        li {
            font-size: 0.95rem;
            color: #fff;
        }
    }

    span {
        text-transform: uppercase;
        background-color: #0C8E86;
        font-weight: 700;
        font-size: 0.8rem;
        letter-spacing: 1.4px;
        color: #fff;
        padding: 15px 30px;
        border-radius: 23px;

        transition: all 0.45s ease;

        display: block;

        cursor: pointer;

        &:hover {
            transform: scale(1.07);
            box-shadow: 0px 18px 46px rgba(0,0,0,0.1);
        }
    }

    ${media.lessThan("medium")`
        margin: 0 30px;

        text-align: center;

        h6 {
            color: #29559C;
        }

        h4 {

        color: #303030;

        }

        ul {
            li {
                color: #303030;
            }
        }

    `}
    
    ${media.lessThan("medium")`
        h2, h6, h4 {
            text-align: center;
            width: 100%;
        }

        hr {
            margin: 10px auto;
            width: 90%;
        }

        span {
            align-self: center;
        }
    `}

`

export const ImagesGrid = styled.section`

    position: relative;
    z-index: 100;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 950px;
    margin: 0 auto;
    margin-bottom: 50px;
    

`

export const ImgAbout = styled.div`
    display: block;
    height: 100%;
    background-color: #29669c;
    flex: 0 1 268px;
    margin: 10px;
    background-image: url(${ props => props.img }); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    
    transition: all 0.5s ease;
`