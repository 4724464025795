import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import * as S from "./styled"

const About = ({ images }) => {

  const { aboutImages } = useStaticQuery(
    graphql`
      query {
        aboutImages: allFile(filter: {absolutePath: {regex: "/(images)/" }}) {
          edges {
            node {
              childImageSharp {
                fluid(quality: 100) {
                  src
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <>
      <S.BackgroundBox img={aboutImages.edges[7].node.childImageSharp.fluid.src} />
      <S.AboutSecContainer>
        <S.Header>
          <h3>coluna</h3>
        </S.Header>
        <S.AboutInnerContainer>
          <S.AboutImageContainer>
            <Img
              fluid={aboutImages.edges[6].node.childImageSharp.fluid}
              alt="Médico"
            />
          </S.AboutImageContainer>
          <S.TextContainer >
            <h2>Dr. Darby Lira Tisatto</h2>
            <h6>Especialista em Cirurgia da Coluna</h6>
            <hr />
            <h4>CRM-RS 37117 <br/> RQE 31132</h4>
            <ul>
              <li>
                <p>- Formado em Medicina pela Universidade de Caxias do Sul-RS (UCS)</p>
              </li>
              <li>
                <p>- Residência médica em Ortopedia e Traumatologia no Hospital Ortopédico/Hospital de Clínicas de Passo Fundo-RS.</p>
              </li>
              <li>
                <p>- Especialização em Cirurgia da Coluna pelo Hospital Ortopédico, também em Passo Fundo-RS.</p>
              </li>
              <li>
                <p>- Membro titular da Sociedade Brasileira de Ortopedia e Traumatologia (SBOT).</p>
              </li>
              <li>
                <p>- Título de especialista pela Sociedade Brasileira de Coluna (SBC).</p>
              </li>      
              <li>
                <p>- Atualmente com atendimentos na cidade de Caxias do Sul.</p>
              </li>      
            </ul>
          </S.TextContainer>
        </S.AboutInnerContainer>
      </S.AboutSecContainer>
      <S.ImagesGrid>
          {
            images.map((image) => {
              return (
                <S.ImgAbout>
                  <Img fluid={image.node.frontmatter.img.childImageSharp.fluid}  />
                </S.ImgAbout> 
              )
            })
          }
      </S.ImagesGrid>
    </>
  )
}

export default About